import { Component, OnInit } from '@angular/core';
import { Product } from '@model/catalog/products/product';
import { Scheme } from '@model/scheme.class';
import { CartService } from '@services/cart.service';
import { finalize } from 'rxjs/operators';
import { KeyValuePipe, NgClass } from '@angular/common';
import { PriceComponent } from '@base/price/price.component';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'rcbt-modal-stock-choice',
  templateUrl: './modal-stock-choice.component.html',
  styleUrls: ['./modal-stock-choice.component.scss'],
  standalone: true,
  imports: [PriceComponent, NgClass, KeyValuePipe],
})
export class ModalStockChoiceComponent implements OnInit {
  public sourceProducts: Product[];
  public currentScheme: Scheme;
  public popinProducts: { [key: string]: Product[] } = {};
  public loading = false;
  public selectedProducts: { [key: string]: Product } = {};

  constructor(
    private ref: DialogRef,
    private cartService: CartService,
  ) {}

  public ngOnInit(): void {
    this.sourceProducts = this.ref.data?.sourceProducts;
    this.currentScheme = this.ref.data?.currentScheme;
    this.loading = true;
    this.sourceProducts.forEach((sourceProduct: Product) => {
      if (this.popinProducts[sourceProduct.gencode]) {
        this.popinProducts[sourceProduct.gencode].push(sourceProduct);
      } else {
        this.popinProducts[sourceProduct.gencode] = [sourceProduct];
      }
    });
    this.cartService
      .simulateProductPromotions(Object.values(this.popinProducts).flat())
      .pipe(finalize(() => (this.loading = false)))
      .subscribe();
  }

  public isSelected(gencode: string): boolean {
    return !!this.selectedProducts[gencode];
  }

  public select(product: Product): void {
    this.selectedProducts[product.gencode] = product;
  }

  public confirm(): void {
    this.ref.close(Object.values(this.selectedProducts));
  }

  public dismiss(): void {
    this.ref.close();
  }
}
