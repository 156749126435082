<div class="modal-stock-choice p-b-2" data-cy="modal-stock-choice">
  <div class="modal-header p-8">
    <div class="head">
      <h2 class="title">Choisir un produit</h2>
    </div>
    <div class="modal-close" (click)="dismiss()">
      <span class="icon is-medium">
        <i class="tri-times" aria-hidden="true"></i>
      </span>
    </div>
  </div>

  <div class="modal-body p-x-30">
    @for (gencode of popinProducts | keyvalue; track gencode.key) {
      @for (product of gencode.value; track product) {
        <div class="promos-ticket-item p-5">
          <h3 class="title is-size-5 m-b-0">
            @if (product.type_id === 'produit_ca') {
              <span class="club-mention">*&nbsp;</span>
            }
            {{ product.name }}
          </h3>
          <rcbt-price [product]="{ price: product.price, oldPrice: product.oldPrice }"></rcbt-price>
          <button
            type="button"
            class="button is-primary"
            [attr.data-cy]="'btn-product-choice-' + (product.type_id === 'produit_ca' ? 'CA' : 'BT')"
            (click)="select(product)"
            [disabled]="loading || isSelected(product.gencode)"
            [ngClass]="{ 'action-feedback': loading }"
          >
            Choisir
          </button>
        </div>
      }
    }
  </div>

  <div class="is-flex is-centered m-t-4">
    <button class="button is-info" (click)="confirm()">Continuer</button>
  </div>
</div>
