import { from as observableFrom, Observable, of, of as observableOf, Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap, tap } from 'rxjs/operators';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import { AppErrorCodes, PaymentType } from '@interfaces/cart.interface';
import { CartService, PaymentMode } from '@services/cart.service';
import {
  IFundingCtx,
  IVirtualSchemeCtx,
  IPostCatalogResponse,
  IVirtualProductCtx,
} from '../../../contextualized-catalog/dtos/contextualized-product-output.dto';
import { Scheme } from '@model/scheme.class';
import { CheckoutStepperService } from '@services/checkout-stepper.service';
import { AlertService } from '@services/alert.service';
import { ConsumerLoanService } from '../../../consumer-loan/consumer-loan.service';
import { ModalConsentComponent } from '@components/modal/modal-consent/modal-consent.component';
import { ScanditService } from '@services/scandit.service';
import { Catalog } from '@model/catalog/products/catalog';
import { Crv } from '@model/catalog/products/crv';
import { Accessory } from '@model/catalog/products/equipement/accessory';
import { Phone } from '@model/catalog/products/equipement/complex/phone';
import { Box } from '@model/catalog/products/equipement/complex/phone/box';
import { Sim } from '@model/catalog/products/equipement/sim';
import { Configuration, ProductSerialized } from '@model/catalog/products/interface/configurable';
import { JsonProduct } from '@model/catalog/products/interface/context';
import { MobileTakeBack } from '@model/catalog/products/mobileTakeBack';
import { Product } from '@model/catalog/products/product';
import { Service } from '@model/catalog/products/service';
import { Plan } from '@model/catalog/products/subscription/plan';
import { Faim } from '@model/catalog/products/subscription/plan/fai/faim';
import { Mobile } from '@model/catalog/products/subscription/plan/mobile';
import { FaimSensation } from '@model/catalog/products/subscription/plan/premium/faim_sensation';
import { Sensation } from '@model/catalog/products/subscription/plan/premium/sensation';
import { Simo } from '@model/catalog/products/subscription/plan/simo';
import { Sowo } from '@model/catalog/products/subscription/plan/sowo';
import { VirtualScheme } from '../../../contextualized-catalog/models/virtual-scheme';
import { FundingEnum } from '@interfaces/funding.interface';
import { CustomerCategory } from '@checkout/cart/customer/customer.interface';
import { SchemeService } from '@services/scheme.service';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { PromoRenewService } from '@services/promo-renew.service';
import { SimService } from '@services/sim.service';
import { OnCatalogCtxLoadedConfig, ViewService } from '@services/view.service';
import { PromotedStatusEnum } from '@model/catalog/products/interface/promoted-status.enum';
import { VirtualProduct } from '../../../contextualized-catalog/models/virtual-product';
import { NgClass, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PriceComponent } from '@base/price/price.component';
import { CustomCurrencyPipe } from '@base/pipes/currency.pipe';
import { DialogService } from '@ngneat/dialog';
import { ConfirmationDialogComponent } from '@components/modal/confirmationDialog/confirmation-dialog.component';
import { ProductsService } from '@services/products.service';

@Component({
  selector: 'rcbt-catalog-category-view-abs',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, PriceComponent, AsyncPipe, CustomCurrencyPipe],
})
export class PlansComponent implements OnInit, OnDestroy {
  public phonesAlone: IPostCatalogResponse[] = [];
  @Input() public scanCode: string;
  @Input() public odr: boolean;
  @Input() public equipmentType: string;
  @Output() public onClose = new EventEmitter<boolean>();

  public fundingMapping = {
    [PaymentType.credit3]: FundingEnum.younitedBy3,
    [PaymentType.credit12]: FundingEnum.younitedBy12,
    [PaymentType.credit24]: FundingEnum.younitedBy24,
    [PaymentType.credit36]: FundingEnum.younitedBy36,
  };

  public productLabel: string;
  public currentEquipment: IPostCatalogResponse;
  public produitsContextualises: IPostCatalogResponse[];
  public produitsFiltres: IPostCatalogResponse[];
  public selectedConfiguration: Configuration = { color: '', capacity: '' };
  public addLoading: string;
  public loading$: Observable<boolean>;
  public showSimoProTab = false;
  public showSensationProTab = false;
  public isPro: boolean;
  public renewOwnedPlan: string;
  public currentPlan: string;
  public isBox = false;
  public plansType: {
    type: string;
    value: string;
    label: string;
    isBoxPlan: boolean;
    isPro?: boolean;
    category: string;
  }[] = [];
  public planType: string = Sensation.planType;
  public isRenew = false;
  public paymentMode: PaymentMode;
  public equipmentQvScheme: VirtualScheme;
  public plansQvSchemes: VirtualScheme[];
  public sapicEDPAvailable = false;
  public sapicCreditAvailable = false;
  public marketLine = 'GP';
  public readonly defaultCreditType = PaymentType.credit24;
  protected userEligibleCredit: boolean;
  protected currentScheme: Scheme;
  protected allPlans: JsonProduct[] = [];
  private lastPlanType: string;
  private lastSelectedPaymentMode: PaymentMode;
  private subscriptions: Subscription = new Subscription();

  constructor(
    protected route: ActivatedRoute,
    protected cartService: CartService,
    protected router: Router,
    protected checkoutStepperService: CheckoutStepperService,
    protected alertService: AlertService,
    protected consumerLoanService: ConsumerLoanService,
    protected scanditService: ScanditService,
    protected customerService: CustomerService,
    protected promoRenewService: PromoRenewService,
    public globalLoaderService: GlobalLoaderService,
    private schemeService: SchemeService,
    private simService: SimService,
    private viewService: ViewService,
    private dialog: DialogService,
    private productsService: ProductsService,
  ) {}

  public ngOnInit(): void {
    this.loading$ = this.globalLoaderService.isAppOnLoadingStatus$;
    this.currentScheme = this.cartService.getCurrentScheme();
    this.isBox = this.equipmentType === 'box';
    this.productLabel = this.isBox ? Box.boxLabel : Phone.phoneLabel;
    if (this.isBox) {
      this.planType = Faim.planType;
    }
    this.currentPlan = this.currentScheme.getProductByType(Plan)?.gencode;
    this.initPlansType();
    this.subscriptions.add(
      this.viewService.onCatalogCtxLoaded$.subscribe((qvModel: OnCatalogCtxLoadedConfig) => {
        this.produitsContextualises = qvModel.produitsContextualises;
        this.currentEquipment = qvModel.currentEquipment;
        this.checkPlanType();
        this.refreshPlans();
      }),
    );
    this.userEligibleCredit = this.consumerLoanService.isEligibleCredit();
  }

  public getAllContextualisedProducts(): void {
    this.viewService.getAllContextualisedProducts$.emit(this.getCurrentPlanCategory());
  }

  public getCurrentPlanCategory(): string {
    return this.plansType.find(planType => this.planType === planType.type).category;
  }
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public addPhoneOnly(): void {
    this.add(this.equipmentQvScheme.equipement.ctxProduct.gencode);
  }

  /**
   * Add all products scheme of QV plan selected in cart service
   * We set currentSchemeUniqueId, this is update by cart on add or other service
   * @param scheme
   */
  public add(gencodePhone: string, virtualPlan?: VirtualProduct): void {
    let fundingAvailable = FundingEnum.younitedBy24;
    const scheme = this.generateSchemeFromProduct(virtualPlan);

    const virtualSchemeWithPlan = this.plansQvSchemes.find(
      virtualScheme => virtualScheme?.plan?.ctxProduct?.gencode === virtualPlan?.ctxProduct?.gencode,
    );

    if (virtualSchemeWithPlan) {
      const defaultFunding = virtualSchemeWithPlan?.equipement?.credits?.find(
        credit => fundingAvailable === this.fundingMapping[credit.type],
      );

      if (!defaultFunding && virtualSchemeWithPlan.equipement?.credits?.length) {
        fundingAvailable = this.fundingMapping[virtualSchemeWithPlan.equipement.credits[0].type];
      }
    }

    this.addLoading = virtualPlan?.ctxProduct.gencode || gencodePhone;
    this.addRecur(scheme, false)
      .pipe(
        mergeMap(() => {
          this.setFundingMode(scheme, fundingAvailable);
          return this.cartService.refreshCart();
        }),
      )
      .subscribe(
        () => {
          this.goToCart();
          this.addLoading = null;
        },
        error => {
          if (error.code === AppErrorCodes.conflict) {
            this.openModalReplaceProduct(gencodePhone, virtualPlan, fundingAvailable);
          } else {
            this.alertService.errorEmitter.next("Erreur serveur, impossible d'ajouter le produit au panier !");
          }
          this.addLoading = null;
        },
      );
  }

  public openModalReplaceProduct(
    gencodePhone: string,
    virtualPlan: VirtualProduct,
    fundingAvailable: FundingEnum,
  ): void {
    const scheme = this.generateSchemeFromProduct(virtualPlan);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { minWidth: '80%', maxWidth: '80%' });

    dialogRef.afterClosed$.subscribe(confirmed => {
      if (confirmed) {
        this.reAddDeletedProduct(scheme);
        this.copyMobileData(scheme);
        this.addLoading = virtualPlan?.ctxProduct.gencode || gencodePhone;
        const removeAllProductObs: Observable<boolean> = !virtualPlan?.ctxProduct.gencode
          ? this.cartService.removeAllProducts(this.getProductsToKeep(scheme))
          : of(null);
        removeAllProductObs
          .pipe(
            mergeMap(() => this.addRecur(scheme, true)),
            tap(() => this.setFundingMode(scheme, fundingAvailable)),
            mergeMap(() => this.cartService.refreshCart()),
          )
          .subscribe(
            () => {
              this.goToCart();
              this.cartService.modifiedOffer.emit(true);
            },
            err => {
              const msg = err?.msgRetour ? err.msgRetour : err;
              this.alertService.errorEmitter.next(`Erreur serveur, impossible d'ajouter le produit au panier ! ${msg}`);
            },
          );
      }
    });
  }

  public isPremiumSelected(): boolean {
    return [Sensation.planTypePro, Sensation.planType].includes(this.planType);
  }

  public showEdpPayment(): boolean {
    return this.sapicEDPAvailable;
  }

  public updateCategory(): void {
    if (this.lastPlanType !== this.planType) {
      if (!!this.cartService.cart.creditData && this.sapicCreditAvailable) {
        this.paymentMode = PaymentMode.credit;
      } else if (this.sapicEDPAvailable) {
        this.paymentMode = PaymentMode.edp;
      } else {
        this.paymentMode = PaymentMode.cash;
      }
    }
    this.lastPlanType = this.planType;
    if (this.isRenew) {
      this.refreshPlans();
    } else {
      this.viewService.getContextualizedCatalogForPlans$.emit(this.getCurrentPlanCategory());
    }
  }

  public refreshPlans(): void {
    this.filterPlans();
    this.checkCreditAvailability();
    this.checkSapicEdpAvaibility();
    if (!this.paymentMode) {
      this.updateSelectedPaymentMethod();
    }
    this.updateEquipmentOnlyQvScheme();
    this.updatePlansQvSchemes();
    if (this.consumerLoanService.needConsent() && this.paymentMode === PaymentMode.credit) {
      this.displayInfoPopup();
    } else {
      this.lastSelectedPaymentMode = this.paymentMode;
    }
  }

  public filterPlans(): void {
    this.produitsFiltres = [];
    const plan = this.plansType.find(pl => pl.type === this.planType);
    this.produitsFiltres = this.produitsContextualises.filter(
      product => product.categories.includes(plan.category) && product.excluPro === !!plan.isPro,
    );
  }

  public checkCreditAvailability(): void {
    this.sapicCreditAvailable = false;
    if (!this.scanditService.isOnTabletOrTpvActive()) {
      return;
    }
    this.produitsFiltres.forEach((planResult: IPostCatalogResponse) => {
      planResult.panierSimule.parcours.forEach((parcoursCourant: IVirtualSchemeCtx) => {
        const phoneCourant: IVirtualProductCtx = parcoursCourant.produits.find(
          (p: IPostCatalogResponse) => p.gencode === this.currentEquipment.gencode,
        );
        if (phoneCourant) {
          this.sapicCreditAvailable =
            this.sapicCreditAvailable ||
            phoneCourant.financements.some((funding: IFundingCtx) =>
              [PaymentType.credit3, PaymentType.credit12, PaymentType.credit24, PaymentType.credit36].includes(
                funding.type,
              ),
            );
        }
      });
    });
  }

  public checkSapicEdpAvaibility(): void {
    this.produitsFiltres.forEach((planResult: IPostCatalogResponse) => {
      planResult.panierSimule.parcours.forEach((parcoursCourant: IVirtualSchemeCtx) => {
        const phoneCourant: IVirtualProductCtx = parcoursCourant.produits.find(
          (p: IPostCatalogResponse) => p.gencode === this.currentEquipment.gencode,
        );
        if (phoneCourant) {
          this.sapicEDPAvailable =
            this.sapicEDPAvailable ||
            phoneCourant.financements.some((funding: IFundingCtx) => funding.type === PaymentType.edp);
        }
      });
    });
    if (!this.sapicEDPAvailable) {
      this.currentEquipment.financements.some((funding: IFundingCtx) => funding.type === PaymentType.edp);
    }
  }

  public displayInfoPopup(): void {
    const dialogRef = this.dialog.open(ModalConsentComponent, { minWidth: '80%', maxWidth: '80%' });

    dialogRef.afterClosed$.subscribe(result => {
      if (result) {
        this.consumerLoanService.saveConsent().subscribe();
        this.consumerLoanService.onSaveConsentment.next(true);
      } else {
        if (this.consumerLoanService.needConsent()) {
          this.paymentMode = this.lastSelectedPaymentMode;
          this.updatePlansQvSchemes();
        }
        this.consumerLoanService.onSaveConsentment.next(false);
      }
    });
  }

  public isCreditModeActive(): boolean {
    return this.paymentMode === PaymentMode.credit;
  }

  protected updateSelectedPaymentMethod(): void {
    if (!!this.cartService.cart.creditData && this.sapicCreditAvailable) {
      this.paymentMode = PaymentMode.credit;
    } else if (this.sapicEDPAvailable) {
      this.paymentMode = PaymentMode.edp;
    } else if (!this.paymentMode) {
      this.paymentMode = PaymentMode.cash;
    }
  }

  protected sortPlans(plansResult: IPostCatalogResponse[]): IPostCatalogResponse[] {
    return plansResult.sort((a, b) => {
      if (a.prix instanceof Number || b.prix instanceof Number) {
        return;
      }
      return b.prix.initial - a.prix.initial;
    });
  }

  protected updateEquipmentOnlyQvScheme(): void {
    this.equipmentQvScheme = new VirtualScheme();
    this.equipmentQvScheme.setEquipmentData(this.currentEquipment, this.paymentMode, this.currentEquipment.gencode);
  }

  protected updatePlansQvSchemes(): void {
    this.plansQvSchemes = [];
    this.produitsFiltres.forEach((planGlobalResult: IPostCatalogResponse) => {
      const parcoursCourant: IVirtualSchemeCtx = planGlobalResult.panierSimule.parcours.find(
        (p: IVirtualSchemeCtx) => p.estCourant,
      );
      const equipement: IVirtualProductCtx = parcoursCourant.produits.find(
        (p: IPostCatalogResponse) => p.gencode === this.currentEquipment.gencode,
      );
      if (
        this.paymentMode === PaymentMode.credit &&
        !equipement.financements.some((fin: IFundingCtx) =>
          [PaymentType.credit3, PaymentType.credit12, PaymentType.credit24, PaymentType.credit36].includes(fin.type),
        )
      ) {
        return;
      }
      const plansQvSchemes: VirtualScheme = new VirtualScheme();
      plansQvSchemes.setEquipmentData(planGlobalResult, this.paymentMode, this.currentEquipment.gencode);
      const plan: IVirtualProductCtx = parcoursCourant.produits.find(
        (p: IPostCatalogResponse) => p.gencode === planGlobalResult.gencode,
      );
      plansQvSchemes.setPlanData(plan);
      this.plansQvSchemes.push(plansQvSchemes);
    });
  }

  protected generateSchemeFromProduct(virtualProduct?: VirtualProduct): Scheme {
    const scheme = this.schemeService.getNewScheme(this.cartService.getCurrentScheme().browseType);
    const phone: Phone = Catalog.getInstanceByType(this.currentEquipment.type);
    phone.gencode = this.currentEquipment.gencode;
    if (this.isRenew) {
      phone.promotedStatus = this.currentEquipment.recommande
        ? PromotedStatusEnum.promoted
        : PromotedStatusEnum.notpromoted;
    }
    phone.setScanCode(this.scanCode);
    scheme.add(phone);
    if (virtualProduct) {
      const planProduct: Plan = Catalog.getInstanceByType<Plan>(virtualProduct.ctxProduct.type);
      planProduct.gencode = virtualProduct.ctxProduct.gencode;
      scheme.add(planProduct);
    }
    return scheme;
  }

  private initPlansType(): void {
    const customerCtx = this.customerService.customer;
    this.plansType = [
      {
        type: Faim.planType,
        value: Faim.planType,
        label: Faim.planLabel,
        isBoxPlan: true,
        isPro: false,
        category: 'faim_old_rcbt',
      },
      {
        type: FaimSensation.planType,
        value: FaimSensation.planType,
        label: FaimSensation.planLabel,
        isBoxPlan: true,
        isPro: false,
        category: 'faim_premium_rcbt',
      },
      {
        type: Sensation.planType,
        value: Sensation.planType,
        label: Sensation.planLabel,
        isBoxPlan: false,
        isPro: false,
        category: 'plansensation_rcbt',
      },
      {
        type: Sensation.planTypePro,
        value: Sensation.planType,
        label: Sensation.planLabel,
        isBoxPlan: false,
        isPro: true,
        category: 'plansensation_rcbt',
      },
      {
        type: Simo.planType,
        value: Simo.planType,
        label: Simo.planLabel,
        isBoxPlan: false,
        isPro: false,
        category: 'simo_rcbt',
      },
      {
        type: Simo.planTypePro,
        value: Simo.planType,
        label: Simo.planLabel,
        isBoxPlan: false,
        isPro: true,
        category: 'simo_rcbt',
      },
      {
        type: Sowo.planType,
        value: Sowo.planType,
        label: Sowo.planLabel,
        isBoxPlan: false,
        isPro: false,
        category: 'byou_rcbt',
      },
    ];
    // get only plan not pro is GP Client
    this.isPro = customerCtx.category === CustomerCategory.pro;
    this.plansType = this.plansType.filter(catPlan => !catPlan.isPro);
    this.plansType = this.plansType.filter(catPlan => catPlan.isBoxPlan === this.isBox); // get only box plans or opposite
  }

  /**
   * re Add the deleted Sim
   * @param {Scheme} scheme
   * @returns {Observable<boolean>[]}
   */
  private reAddDeletedProduct(scheme: Scheme): void {
    let newPlan: Plan = scheme.getProductByType(Mobile);
    if (newPlan === undefined) {
      newPlan = scheme.getProductByType(Faim);
    }
    const pConflicts: Product[] = this.currentScheme.rules.getConflictProject(this.currentScheme, newPlan);

    pConflicts.forEach((pDeleted: Product) => {
      if (pDeleted instanceof Sim) {
        scheme.add(pDeleted);
      }
    });
  }

  /**
   * Copy serialized data from the old plan deleted
   * @param {Scheme} scheme
   */
  private copyMobileData(scheme: Scheme): void {
    const newPlan: Mobile = scheme.getProductByType(Mobile);
    const oldPlan: Mobile = this.currentScheme.getProductByType(Mobile);
    if (newPlan && oldPlan) {
      newPlan.setConfiguration(oldPlan.getFilteredConfiguration());
    }
  }

  private getProductsToKeep(scheme: Scheme): Product[] {
    let productsToKeep: Product[] = [];
    const mobileTakeBackProduct: Product = this.cartService.getCurrentScheme().getProductByType(MobileTakeBack);
    if (mobileTakeBackProduct && scheme.getProductByType(Phone)) {
      productsToKeep.push(mobileTakeBackProduct);
    }
    productsToKeep = productsToKeep.concat(
      this.cartService
        .getCurrentScheme()
        .products.filter(
          elt => elt instanceof Phone || elt instanceof Accessory || elt instanceof Crv || elt instanceof Service,
        ),
    );
    return productsToKeep;
  }

  private addNecessaryAutoAdds(product: ProductSerialized, force: boolean): Observable<null> {
    const listGencode = this.productsService.getAutoAddGencodesBySchemeType(
      product.data.to_add_front,
      this.cartService.getCurrentScheme().browseType,
    );

    if (!listGencode.length) {
      return of(null);
    }

    this.productsService
      .getJsonProductsByFilter({
        listGencode: listGencode.join(','),
      })
      .subscribe(() => {
        listGencode.forEach(item =>
          this.cartService
            .addProduct({ gencode: item }, force)
            .pipe(mergeMap(() => this.cartService.refreshCart()))
            .subscribe(),
        );
      });
    return of(null);
  }

  private addRecur(
    scheme: Scheme,
    force: boolean,
    promise: Observable<boolean> = observableFrom([true]),
    index: number = -1,
  ): Observable<boolean> {
    index++;
    if (index === scheme.products.length) {
      return promise;
    }
    return this.addRecur(
      scheme,
      force,
      promise.pipe(
        mergeMap(res => {
          if (!res) {
            return observableOf(res);
          }
          return this.cartService
            .addProduct(scheme.products[index].serialize(), force)
            .pipe(
              mergeMap(product =>
                this.addNecessaryAutoAdds(product, force).pipe(mergeMap(() => observableFrom([true]))),
              ),
            );
        }),
      ),
      index,
    );
  }

  private goToCart(): void {
    this.cartService.save();
    this.addLoading = '';
    // Je comprends pas pourquoi cette condition;, j'enlève
    // if (this.router.url.startsWith('/panier')) {
    this.simService.loadContextualizedSim$.emit();
    this.onClose.emit(true);
    // }
    this.checkoutStepperService.goToStep(this.checkoutStepperService.getStepByCode('cart'));
  }

  private setFundingMode(schemeToAdd: Scheme, fundingAvailable: FundingEnum): void {
    if (this.paymentMode === PaymentMode.edp && this.canSetEdpForSchemeToAdd(schemeToAdd)) {
      this.cartService.setEdpFundingMode();
    } else if (this.paymentMode === PaymentMode.credit) {
      this.cartService.setCreditFundingMode(fundingAvailable);
    } else {
      this.cartService.setCashFundingMode();
    }
  }

  private canSetEdpForSchemeToAdd(schemeToAdd: Scheme): boolean {
    const plan = schemeToAdd.getProductByType(Plan);
    if (
      plan &&
      !!this.plansQvSchemes.find(qvScheme => qvScheme.plan.ctxProduct.gencode === plan.gencode).equipement.edp
    ) {
      return true;
    }
    if (!plan && !!this.equipmentQvScheme.equipement.edp) {
      return true;
    }
    return false;
  }

  private checkPlanType(): void {
    if (
      this.planType === Sensation.planTypePro &&
      !this.produitsContextualises.some(ctxProduct => ctxProduct.type === Sensation.planType && ctxProduct.excluPro)
    ) {
      this.planType = Sensation.planType;
    }
  }
}
