<div class="dialog-modal-container no-gutters " data-cy="credit-consent-popin">
    <div class="modal-header is-centered">
        <h2 class="title-border">Financement à crédit</h2>
    </div>
    <div class="modal-text">
        <p [innerHTML]="message"></p>
        <div class="form-check form-check-label m-b-4">
            <input type="checkbox" class="form-check-input" id="accept-consent" [(ngModel)]="consentAgreed"   data-cy="accept-consent">
            <label for="accept-consent">
                En cochant cette case, j'accepte que Bouygues Telecom procède aux traitements de mes données listées ci-dessus.

                Si je ne coche pas cette case, Bouygues Telecom ne pouvant procéder aux traitements de mes données personnelles, je ne me verrai pas proposer le financement à crédit.
            </label>
        </div>
    </div>
    <div class="modal-actions">
        <button class="button is-primary" (click)="closeModal(false)" data-cy="credit-consent-cancel">Précédent</button>
        <button class="button is-info" (click)="closeModal(true)" data-cy="credit-consent-valid" [disabled]="!consentAgreed || loading">Suivant</button>
    </div>
</div>
