<div class="box has-text-centered" data-cy="confirmation-dialog">
  <div class="box-content">
    <div class="rows">
      <div class="row">
        Le produit et/ou l'offre que vous ajoutez au panier va remplacer le produit et/ou l'offre précédemment <br />
        sélectionné. Voulez-vous continuer?
      </div>
      <div class="row">
        <div class="columns">
          <div class="column is-6">
            <button type="button" class="button is-primary" data-cy="confirmation-dialog-non" (click)="decline()">Non</button>
          </div>
          <div class="column is-6">
            <button type="button" class="button is-info" data-cy="confirmation-dialog-oui" (click)="accept()">Oui</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
