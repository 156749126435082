import { Step } from '@components/stepper/step.abstract';
import { Injectable } from '@angular/core';
import { CartService } from '@services/cart.service';
import { Plan } from '@model/catalog/products/subscription/plan';
import { Insurance } from '@model/catalog/products/subscription/insurance';
import { Phone } from '@model/catalog/products/equipement/complex/phone';
import { InsurancePartner } from '@model/catalog/products/subscription/insurance-partner';
import { Service } from '@model/catalog/products/service';
import { Crv } from '@model/catalog/products/crv';
import { Prepaid } from '@model/catalog/products/subscription/plan/prepaid';
import { Accessory } from '@model/catalog/products/equipement/accessory';
import { CaProduct } from '@model/catalog/products/caProduct/caProduct';

@Injectable({
  providedIn: 'root',
})
export class CrossSellingStepService extends Step {
  public code = 'ventes-complementaires';
  public path = '/panier/ventes-complementaires';
  public label = 'Ventes complémentaires';
  public labelButton = 'Ventes comp.';

  constructor(private cartService: CartService) {
    super();
  }

  get isAllowed(): boolean {
    const currentScheme = this.cartService.getCurrentScheme(false);

    return (
      currentScheme &&
      (!!currentScheme.getProductByType(Plan) ||
        !!currentScheme.getProductByType(Service) ||
        !!currentScheme.getProductByType(Crv) ||
        !!currentScheme.getProductByType(Prepaid) ||
        !!currentScheme.getProductByType(Insurance) ||
        !!currentScheme.getProductByType(InsurancePartner) ||
        !!currentScheme.getProductByType(Phone) ||
        !!currentScheme.getProductByType(Accessory) ||
        !!currentScheme.getProductByType(CaProduct) ||
        !!this.cartService.getCurrentScheme().addedInsurances.length) &&
      (!currentScheme.isBigtrustSav() || currentScheme.isRenew())
    );
  }
  set isAllowed(val: boolean) {
    return;
  }
}
