<div class="p-5 dialog-modal-container">
    <header>
      <div class="is-flex is-spaced-between">
        <h2 class="title is-level-1">Attention</h2>
        <span class="icon is-medium" aria-label="Icone de taille large" (click)="closeModal(false)">
          <i class="tri-times-circle" aria-hidden="true"></i>
        </span>
      </div>
    </header>
    <div class="modal-text">
      <div class="text" [innerHTML]="ref.data.message"></div>
    </div>
    <div class="buttons is-flex is-spaced-evenly m-t-4">
        <button class="button is-danger" (click)="closeModal(false)">Annuler</button>
        <button class="button is-info" (click)="closeModal(true)">Valider</button>
    </div>
  </div>
  
